/* AnimatedMap.css */
.map-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust this value as needed */
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.country-list {
    position: absolute; /* Ensure it is positioned relative to the map */
    top: 80%; /* Position it vertically, so it aligns with the middle of Africa */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust for proper centering */
    z-index: 10; /* Ensure it appears above the map */
    background-color: rgba(255, 255, 255, 0.9); /* Optional: Add transparency for better blending with the map */
    padding: 10px; /* Padding for neat look */
    border-radius: 8px; /* Round the corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    
  }

.country-list h2 {
  margin-bottom: 10px;
  color: #333;
  font-size: 18px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.country-button {
  background: #93c5fd;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
}

.country-button:hover,
.country-button.active {
  background: #60a5fa;
  color: white;
}

/* Tooltip styles */
#map-tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: white !important;
  padding: 8px 12px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}