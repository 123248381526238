@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'leaflet/dist/leaflet.css';
 

/* In your App.css or relevant CSS file */
header {
  position: fixed;  /* Keep the header fixed */
  top: 0;           /* Stick it to the top */
  left: 0;
  right: 0;
  z-index: 1000;   /* Ensure it stays on top */
  background-color: white; /* Ensure the background covers underlying content */
  padding: 10px 0; /* Adjust padding to create space */
}


/* Table styles */
.table-container {
  @apply overflow-auto max-h-64 rounded-lg shadow;
}

table {
  @apply w-full border-collapse bg-white;
}

th {
  @apply bg-gray-200 text-left p-3 font-semibold;
}

td {
  @apply border-b p-3;
}

tr:nth-child(even) {
  @apply bg-gray-50;
}

/* Search bar styles */
.search-bar {
  @apply w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500;
}

/* Blur effect for table */
.table-blur-effect {
  @apply absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-white to-transparent pointer-events-none;
}


.app {
  position: relative;
}

.bg-blue-600 {
  background-color: rgba(37, 99, 235, 0.8); /* Adjusted for semi-transparency */
}
 


body {
  background-color: #f7f9fc; /* Light off-white */
  color: #333; /* Dark grey for better readability */
  font-family: 'Arial', sans-serif; /* Simple, clean font */

}

.app {
  position: relative;
}

section {
  padding: 20px;
}

.bg-blue-600 {
  background-color: rgba(173, 216, 230, 0.8); /* Soft light blue with transparency */
}

.table-container {
  overflow-y: auto;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
 

h1, h2, h3 {
  color: #2c3e50; /* Darker shade for headings */
}

button {
  background-color: #60a5fa; /* Soft green for buttons */
  color: white;
}

button:hover {
  background-color: #93c5fd; /* Darker green on hover */
}

input {
  border: 1px solid #b0bec5; /* Light grey border for input */
  border-radius: 5px;
}

input:focus {
  border-color: #4caf50; /* Green border on focus */
  outline: none; /* Remove outline */
}
  
